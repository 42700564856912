var fdaChart = function(className){
    this.elems = document.getElementsByClassName(className);
    this.init();
};

fdaChart.prototype.init = function()
{
    var elems = this.elems;
    for (var index = 0, length = elems.length; index < length; ++index) {
        this.drawChart(elems[index]);
    };
    return this;
};

fdaChart.prototype.drawChart = function(elem){

    elem.style.display = 'none';

    var dataTable = new google.visualization.DataTable();

    var chartCanvas = document.createElement('div');
    chartCanvas.className = "chart";
    elem.parentNode.insertBefore(chartCanvas, elem.nextSibling);

    var chartType = elem.dataset.chartType;

    var columns = elem.getElementsByTagName('th'),
        rows    = elem.getElementsByTagName('tbody')[0].getElementsByTagName('tr');


    for (var index = 0, length = columns.length; index < length; ++index) {
        var label = columns[index].innerText,
            type = columns[index].dataset.type;
        dataTable.addColumn(type, label);
    }

    var data = [];

    for (var indexRows = 0, lengthRows = rows.length; indexRows < lengthRows; ++indexRows) {

        var cells = rows[indexRows].getElementsByTagName('td');
        var dataRow = [];

        for (var indexCells = 0, lengthCells = cells.length; indexCells < lengthCells; ++indexCells) {
            var value = cells[indexCells].innerText;
            if (cells[indexCells].dataset.type === 'number') {
                value = Number(value);
            }
            if (cells[indexCells].dataset.type === 'date') {
                value = new Date(value);
            }
            dataRow.push(value);
        }
        data.push(dataRow);
    }

    dataTable.addRows(data);

    var chart = eval('new google.visualization.' + chartType + '(chartCanvas)');

    var defaultSize = {
        left   : '10%',
        top    : '10%',
        width  : '80%',
        height : '80%'
    }

    var wideChart = {
        left   : '2.5%',
        top    : '10%',
        width  : '95%',
        height : '80%'
    }

    var fullWidthChart = {
        left   : '.5%',
        top    : '1.5%',
        width  : '99%',
        height : '97%'
    }

    var options = {
        defaults : {
            // animation : {
            //     startup : true,
            //     duration : 500,
            //     easing : 'inAndOut'
            // }
        },
        AreaChart : {
            legend    : 'none',
            chartArea : fullWidthChart,
            pointSize : 8,
            lineWidth : 4,
            theme : 'maximized',
            hAxis : {
                format : "EEEE, dd MMM, yyyy",
                gridlines : {
                    color : '#EEE'
                }
            },
            vAxis : {
                minValue : 0,
                format : 'decimal',
                baseline : 0,
                baselineColor : '#AAA',
                gridlines : {
                    color : '#EEE'
                }
            },
            tooltip : {
                // isHtml : true
            }
        },
        LineChart : {
            chartArea : wideChart,
            pointSize : 8,
            lineWidth : 5,
            hAxis : {
                format : 'dd MMM',
                gridlines : {
                    color : '#EEE'
                }
            },
            vAxis : {
                minValue : 0,
                format : '#',
                baseline : 0,
                baselineColor : '#AAA',
                gridlines : {
                    color : '#EEE'
                }
            }
        },
        PieChart : {
            chartArea : defaultSize,
            legend    : 'none',
            pieHole   : 0.55
        },
        GeoChart : {
            chartArea   : defaultSize,
            region      : 'BR',
            displayMode : 'markers'
        }
    }

    var masterOptions = merge_options(options.defaults, options[chartType]);

    // console.log(masterOptions);

    chart.draw(dataTable, masterOptions);

    return this;
};

fdaChart.prototype.colorizeData = function(data){
    for (var i = data.length - 1; i >= 0; i--) {
        data[i].color = this.getRandomColor();
    }
    return data;
};

google.load('visualization', '1.0', {'packages':['corechart'], 'language': 'pt-BR'});
google.setOnLoadCallback(drawFdaCharts);

function drawFdaCharts(){
    var fdachart = new fdaChart('chartTable');
}

$(function() {
    $('.datepicker').datepicker({
        format: "dd/mm/yyyy",
        startDate: "01/09/2015",
        endDate: "0d",
        language: "pt-BR",
        orientation: "auto",
        autoclose: true,
        todayHighlight: true
    });
});

function priceSorter(a, b) {
    a = +a.substring(36);
    b = +b.substring(36);
    if (a > b) return 1;
    if (a < b) return -1;
    return 0;
}

/**
 * Overwrites obj1's values with obj2's and adds obj2's if non existent in obj1
 * @param obj1
 * @param obj2
 * @returns obj3 a new object based on obj1 and obj2
 */
function merge_options(obj1,obj2){
    var obj3 = {};
    for (var attrname in obj1) { obj3[attrname] = obj1[attrname]; }
    for (var attrname in obj2) { obj3[attrname] = obj2[attrname]; }
    return obj3;
}